<template>
    <VField
        v-slot="{ field, errors, meta}"
        v-model="model"
        :name="name || label"
        :label="label || name"
        :rules="rules"
        @input="$emit('update:modelValue', $event.target.value)">
        <div v-bind="$attrs">
            <div
                v-if="(label || $slots.inputHelper) && type !== 'hidden'"
                :class="labelWrapperClasses">
                <label
                    v-if="label && type !== 'hidden'"
                    :for="'input_'+name"
                    :title="labelTitle"
                    :class="labelClasses">{{ label }} {{ required ? '*' : '' }}</label>
                <slot name="inputHelper" />
            </div>

            <slot
                :field="field"
                :meta="meta">
                <div :class="inputWrapperClasses">
                    <input
                        v-if="mask"
                        v-maska="mask"
                        v-bind="{...field, ...inputAttributes}"
                        :class="[props.inputClasses, !meta?.valid && meta?.touched ? props.inputInvalidClasses : '']"
                        :placeholder="placeholder || maskPlaceholder">
                    <input
                        v-else
                        v-bind="{...field, ...inputAttributes}"
                        :class="[props.inputClasses, !meta?.valid && meta?.touched ? props.inputInvalidClasses : '']"
                        :placeholder="placeholder">
                    <slot name="inputIcon" />
                </div>
            </slot>

            <ErrorMessage
                :errors="errors"
                :name="name || label"
                :class="'block ' + errorClasses" />
        </div>
    </VField>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: [String, Number, Date, Object, Array, null],
        default: ''
    },
    name: {
        type: String,
        default: '',
        required: true
    },
    type: {
        type: String,
        default: 'text'
    },
    step: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    autocomplete: {
        type: String,
        default: null
    },
    tabindex: {
        type: [Number, String],
        default: null
    },
    labelWrapperClasses: {
        type: String,
        default: ''
    },
    labelClasses: {
        type: String,
        default: 'form-label'
    },
    inputWrapperClasses: {
        type: String,
        default: ''
    },
    inputClasses: {
        type: String,
        default: 'form-control'
    },
    inputInvalidClasses: {
        type: String,
        default: 'is-invalid'
    },
    errorClasses: {
        type: String,
        default: 'invalid-feedback'
    },
    rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    labelTitle: {
        type: String,
        default: ''
    },
    mask: {
        type: [String, Object],
        default: null,
        description: 'The mask settings for the input, see https://beholdr.github.io/maska/v3/#/vue'
    }
})

const emits = defineEmits(['update:modelValue', 'focus', 'blur', 'mouse-enter', 'mouse-leave'])

const model = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue)
    }
});

const maskPlaceholder = computed(() => {
    const maskString = props.mask?.mask || props.mask || null
    // tokens #, @ and * are reserved for maska, so we replace them with -, unless they are escaped with !
    return maskString?.replace(/(?<!\\)([#@*])/g, '-')
})

const inputAttributes = computed(() => {
    return {
        id: 'input_' + props.name,
        step: props.step,
        type: props.type,
        tabindex: props.tabindex,
        disabled: props.disabled,
        readonly: props.readonly,
        placeholder: props.placeholder,
        autocomplete: props.autocomplete,
        onBlur: (event) => emits('blur', event),
        onFocus: (event) => emits('focus', event),
        onMouseenter: (event) => emits('mouse-enter', event),
        onMouseleave: (event) => emits('mouse-leave', event)
    }
})
</script>